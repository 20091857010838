import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const Services = () => {
  const { allContentfulPalvelut: services } = useStaticQuery(graphql`
    {
      allContentfulPalvelut(sort: { order: ASC, fields: serviceName }) {
        edges {
          node {
            slug
            serviceName
            textIcon
            id
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <section className="services">
        <h2>Palvelut</h2>
        <ul className="card-grid">
          {services?.edges?.map((service) => (
            <li>
              <Link key={service.node.id} to={`/palvelut/${service.node.slug}`}>
                <div className="card pointer">
                  <div className="card-icon">
                    {service.node.textIcon && service?.node?.textIcon}
                  </div>
                  <div className="card-text">{service?.node?.serviceName}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </React.Fragment>
  );
};

export default Services;
