import React from "react";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Seo from "../components/Seo";
import { Link } from "gatsby";

const ServicesPage = () => {
  return (
    <Layout hero={true} heroSmall={true}>
      <Seo title="Palvelut - Kauneuden ilo" />
      <h2>Neostrata verkkokauppa</h2>
      <p>
        Voit ostaa Neostrata -tuotteita myös verkkokaupastamme. Postikulut
        verkkokaupasta ovat 6,5€, ilmaiset yli 70€ tilauksille. Mikäli tilaat
        väh. neljä tuotetta, saat valita lahjan kaupan päälle.
      </p>
      <p>
        <Link
          to="https://www.neostrata.fi/#clinic=kauneudenilo&amp;link=668328aa"
          className="button--outline"
          target="_blank"
          rel="noreferrer"
        >
          Neostrata verkkokauppa
        </Link>
      </p>
      <Services />
    </Layout>
  );
};

export default ServicesPage;
